import React from "react";
import logo from "../../assets/logo.png";
import "./header.css";

const Header = () => {
  const handleLoginClick = () => {
    window.open("https://admin.rastaa.ai/login", "_blank");
  };

  const handleSignUpClick = () => {
    window.open("https://admin.rastaa.ai/client-registration", "_blank");
  };

  return (
    <div className="header-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo"/>
      </div>
      {/* <button
        style={{
          background: "rgb(236 242 248)",
          border: "none",
          borderRadius: "10px",
          color: "#29621b",
          fontWeight: "bold",
          padding: "12px 25px",
          cursor: "pointer",
          fontSize: "18px",
          display: "flex",
          alignItems: "center",
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
          transition: "background 0.3s ease, transform 0.3s ease",
        }}
        onMouseOver={(e) => {
          e.target.style.background = "rgb(236 242 248)";
          e.target.style.transform = "scale(1.1)";
        }}
        onMouseOut={(e) => {
          e.target.style.background = "rgb(236 242 248)";
          e.target.style.transform = "scale(1)";
        }}
      >
        HOME
      </button>
      <style>
        {`
          @media (max-width: 600px) {
            button {
              padding: 8px 20px !important;
              font-size: 14px !important;
            }
          }
        `}
      </style> */}
      <div
       className="login-button"
      >
        <button
          className="login-button-text"
          onClick={handleLoginClick}
          style={{ zIndex: 1 }}
        >
          Login
        </button>
        <span className="separator" style={{ zIndex: 1 }}>|</span>
        <button
          className="sign-up-button"
          onClick={handleSignUpClick}
          style={{ zIndex: 1 }}
        >
          Sign Up
          <span style={{ marginLeft: "5px", fontSize: "14px", zIndex: 1 }}>↗</span>
        </button>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import "./Footer.css";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  const handleClick = () => {
    const phoneNumber = "+918829996143";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div>
      <div onClick={handleClick} className="whatsapp-button">
        <FaWhatsapp />
      </div>
    </div>
  );
};

export default Footer;

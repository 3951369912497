import React from "react";
import logo from "../assets/back.png";
import { FaWhatsapp } from "react-icons/fa";
import "./main.css";
const Main = () => {
  const handleClick = () => {
    const phoneNumber = "+918829996143";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };
  return (
    <div className="main-container">
      <img src={logo} alt="Logo" className="logo" />
      {/* <button
        onClick={handleClick}
        style={{
          background: "rgb(236 242 248)",
          border: "none",
          borderRadius: "20px",
          color: "#29621b",
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.3)",
          fontWeight: "bold",
          padding: "10px 20px",
          cursor: "pointer",
          fontSize: "17px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaWhatsapp
          style={{ color: "#25D366", marginRight: "10px", fontSize: "30px", fontWeight: "bold" }}
        />
        Contact Us
      </button> */}
    </div>
  );
};

export default Main;
